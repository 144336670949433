import React, { useContext } from "react"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Flexbox from "@bankiru/ui-kit/core/Flexbox"
import { DocumentsList } from "./DocumentsList"
import TextHeader from "@bankiru/ui-kit/core/TextHeader"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import image from "../../assests/images/documents.png"
import tabletImage from "../../assests/images/documents-tablet.png"
import PageContext from "../../context/context"
import PageSection from "@bankiru/ui-kit/core/PageSection"
import Panel from "@bankiru/ui-kit/core/Panel"
import { DOCUMENTS, INFORMATION } from "../../constants";
import Text from "@bankiru/ui-kit/core/Text";

export const DocumentsSection = () => {
  const deviceType = useContext(PageContext)
  const { isTablet, isMobile, isDesktop } = deviceType

  return (
    <PageSection indentTop="large">
      <LayoutWrapper id="documents">
        <Panel
          sections={
            <FlexboxGrid
              direction={isMobile ? "vert" : "row"}
              isEqualWidth
              gap={isMobile ? "small" : "default"}
            >
              {isMobile && (
                <TextHeader deviceType="desktop" tagName="h5" size="h5">Основные документы</TextHeader>
              )}
              <Flexbox display="flex" justifyContent="center" alignItems="center">
                <img
                  width={isTablet ? 324 : 480}
                  height={isTablet ? 308 : 404}
                  src={isTablet ? tabletImage : image}
                  alt="документы"
                />
              </Flexbox>
              <Flexbox display="flex" justifyContent="center" alignItems="center">
                <Flexbox
                  display="flex"
                  flexDirection="column"
                  gap={isTablet ? "24" : "32"}
                >
                  {!isMobile && (
                    <TextHeader tagName="h2" size="h2" deviceType={isDesktop ? "desktop" : "tablet"}>
                      Основные документы
                    </TextHeader>
                  )}
                  <Flexbox
                    display="flex"
                    flexDirection="column"
                    gap={isDesktop ? "24" : "16"}
                  >
                    <Flexbox
                      display="flex"
                      flexDirection="column"
                      gap="8"
                    >
                      <Text
                        size={isMobile ? "fs12" : "fs14"}
                        color="secondary"
                      >
                        Информация
                      </Text>
                      <DocumentsList documents={INFORMATION} />
                    </Flexbox>
                    <Flexbox
                      display="flex"
                      flexDirection="column"
                      gap="8"
                    >
                      <Text
                        size={isMobile ? "fs12" : "fs14"}
                        color="secondary"
                      >
                        Правила финансовой платформы
                      </Text>
                      <DocumentsList documents={DOCUMENTS} />
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </FlexboxGrid>
          }
        />
      </LayoutWrapper>
    </PageSection>
  )
}
