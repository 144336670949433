import styled from "styled-components"

export const StyledBackground = styled.div`
  position: relative;
  :after {
    content: "";
    background: radial-gradient(
          63.61% 65.05% at -26.32% 45.82%,
          #c0ffd9 0%,
          rgba(191, 222, 227, 0.3) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(67.53% 69.06% at 97.53% 60.08%, #ffeccc 0%, #b6ddff 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`
