import React from "react"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import UserProfile24 from "@bankiru/icons/react/UserProfile24"
import { LinkText } from "@bankiru/ui-kit/components/Link/LinkText";
import Avatar from "@bankiru/ui-kit/core/Avatar"
import { HeaderNav } from "./HeaderNav"
import { HeaderLogo } from "../HeaderLogo"
import { LINK_TO_DEPOSITS } from "../../constants"

export const HeaderDesktop = () => {
  return (
    <FlexboxGrid justifyContent="space-between" alignItems="center">
      <FlexboxGrid gap="large" alignItems="center">
        <HeaderLogo />
        <HeaderNav />
      </FlexboxGrid>
      <LinkText href={LINK_TO_DEPOSITS}>
        <Avatar src={<UserProfile24 />} alt="avatar" size="24" />
      </LinkText>
    </FlexboxGrid>
  )
}
