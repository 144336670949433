export const MAIL = "info.tst@banki.ru"
export const PHONE_NUMBER = "+84951980345"
export const LINK_TO_MPK =
  "https://www.banki.ru/credit-master/?source=marketplace_main&lp=slider_mpk"
export const LINK_TO_DEPOSITS = "https://www.banki.ru/products/deposits/?source=main_menu_deposits"
export const LINK_TO_APPLICATIONS = "https://www.banki.ru/account-new/applications"
export const MARKETPLACE = "/marketplace/"
export const ADDRESS =
  "117638, Россия (Российская Федерация), г. Москва, ВН. Тер. г. Муниципиальный округ Зюзино, Одесская, дом 2, офис 5"

export const PRIVACY_POLICY = {
  title: "Политика конфиденциальности",
  link: "https://static2.banki.ru/mkp/legal/Privacy_Policy.pdf",
}
export const RULES_ELECTRONIC_DOCUMENT_FLOW = {
  title: "Правила электронного документооборота",
  link: "https://static2.banki.ru/mkp/legal/Rules_electronic_document_flow.pdf",
}
export const TARIFFS_SERVICES_PLATFORM = {
  title: "Тарифы на услуги Оператора платформы",
  link: "https://static2.banki.ru/mkp/legal/Tariffs_services_Platform.pdf",
}
export const STATE_REGISTRATION = {
  title: "Сведения о государственной регистрации",
  link: "https://static2.banki.ru/mkp/legal/Information_state_registration.pdf",
}
export const RULES_PROTECTION_DISCLOSURE = {
  title: "Правила защиты и раскрытия информации",
  link: "https://static2.banki.ru/mkp/legal/Rules_Protection_Disclosure.pdf",
}
export const REGULATIONS = {
  title: "Устав",
  link: "https://static2.banki.ru/mkp/legal/Regulations.pdf",
}
export const RISKS = {
  title: "Правила по рискам",
  link: "https://static3.banki.ru/cfe/0201rules_risks_v2_for_site.pdf",
}
export const SECURITY_MEMO = {
  title: "Памятка по информационной безопасности для клиентов платформы",
  link: "https://static2.banki.ru/cfe/pamyatka_ib_v1.pdf",
}
export const FRAUD_ALERT = {
  title: "Предупреждение о возможных мошеннических действиях",
  link: "https://static2.banki.ru/cfe/alarm_cheaters_bm.pdf",
}
export const TERMS_OF_USE = {
  title: "Пользовательское соглашение",
  link: "https://static2.banki.ru/cfe/1103_Terms_of_use_ed1.pdf",
}
export const PERSONAL_DATA = {
  title: "Политика обработки персональных данных",
  link: "https://static4.banki.ru/cfe/policy_pers_data_AO_digital_tech.pdf",
}

export const INFORMATION = [
  PRIVACY_POLICY,
  RULES_ELECTRONIC_DOCUMENT_FLOW,
  TARIFFS_SERVICES_PLATFORM,
  STATE_REGISTRATION,
  RULES_PROTECTION_DISCLOSURE,
  PERSONAL_DATA,
  REGULATIONS,
  RISKS,
  SECURITY_MEMO,
  FRAUD_ALERT,
  TERMS_OF_USE,
]

export const RULES = {
  title: "Действующая с 24.10.2024 редакция правил финансовой платформы",
  link: "https://static2.banki.ru/mkp/rules_ofp_11.pdf",
}

export const RULES_CHANGES = {
  title: "Список внесенных изменений в правила фин. платформы",
  link: "https://static2.banki.ru/mkp/list_ofp_edits_reasons_11.pdf",
}

export const RULES_CHANGES_LIST = {
  title: "Изменения в правилах фин. платформы",
  link: "https://static2.banki.ru/mkp/rules_ofp_11_edits.pdf",
}

export const DOCUMENTS = [
  RULES,
  RULES_CHANGES,
  RULES_CHANGES_LIST,
];
