import React, { useContext } from "react"
import TextList from "@bankiru/ui-kit/core/TextListLegacy"
import { LinkText } from "@bankiru/ui-kit/components/Link/LinkText";
import PageContext from "../../../context/context"

const getDataList = (list) => {
  return list.map(({ link, title }) => ({
    text: (
        <LinkText target="_blank" href={link} skin="blue">
          {title}
        </LinkText>
    ),
  }));
}

export const DocumentsList = ({ documents }) => {
  const { isMobile } = useContext(PageContext)

  return (
    <TextList
      gapSize="medium"
      textSize={isMobile ? "small" : "large"}
      data={{ list: getDataList(documents) }}
    />
  )
}
