import React, { useContext } from "react"
import { StyledHeaderWrapper } from "./index.styled"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import Panel from "@bankiru/ui-kit/core/Panel"
import { HeaderDesktop } from "../HeaderDesktop"
import { HeaderMobile } from "../HeaderMobile"
import PageContext from "../../context/context"

export const Header = () => {
  const { isDesktop, isMobile } = useContext(PageContext)

  return (
    <Panel
      isNoRoundCorners
      isNoPaddingContent
      sections={
        <LayoutWrapper>
          <StyledHeaderWrapper isMobile={isMobile}>
            {isDesktop ? <HeaderDesktop /> : <HeaderMobile />}
          </StyledHeaderWrapper>
        </LayoutWrapper>
      }
    />
  )
}
