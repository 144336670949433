import React, { useContext } from "react"
import AccordionList from "@bankiru/ui-kit/core/AccordionList"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import PageSection from "@bankiru/ui-kit/core/PageSection"
import { getAccordionData } from "./data"
import PageContext from "../../context/context"

export const AccordionSection = () => {
  const { isMobile } = useContext(PageContext)
  const accordionData = getAccordionData(isMobile)

  return (
    <PageSection skin="white" indentTop="large">
      <LayoutWrapper>
        <AccordionList
          deviceType={isMobile ? 'mobile' : 'desktop'}
          data={accordionData}
        />
      </LayoutWrapper>
    </PageSection>
  )
}
