import React, { useContext } from "react"
import FlexboxGrid, { FlexboxGridItem } from "@bankiru/ui-kit/core/FlexboxGrid"
import { PageTitle } from "./PageTitle"
import { CreditButton } from "./CreditButton"
import image from "../../assests/images/basket.png"
import PageSection from "@bankiru/ui-kit/core/PageSection"
import PageContext from "../../context/context"

export const PageInfo = () => {
  const deviceType = useContext(PageContext)
  const { isDesktop, isTablet, isMobile } = deviceType

  return (
    <PageSection indentTop={"medium"} indentBottom="medium">
      <FlexboxGrid
        isEqualWidth
        gap="xs"
        direction={isMobile ? "vert" : "row"}
        alignItems="center"
      >
        <FlexboxGrid direction="vert" gap={isDesktop ? "default" : "medium"}>
          <PageTitle />
          {!isMobile && (
            <FlexboxGridItem>
              <CreditButton />
            </FlexboxGridItem>
          )}
        </FlexboxGrid>
        <FlexboxGrid alignItems="center" justifyContent="center">
          <img
            width={isTablet ? 287 : 559}
            height={isTablet ? 221 : 432}
            fetchpriority="high"
            src={image}
            alt="корзина"
          />
        </FlexboxGrid>
        {isMobile && <CreditButton />}
      </FlexboxGrid>
    </PageSection>
  )
}
