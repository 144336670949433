import React from "react"
import Master from "@bankiru/icons/react/Master40"
import Stopwatch from "@bankiru/icons/react/Stopwatch40"
import Clock from "@bankiru/icons/react/Clock24"
import Settings from "@bankiru/icons/react/Settings24"

const MasterIcon = {
  size40: <Master />,
}

const StopwatchIcon = {
  size40: <Stopwatch />,
}

const SettingsIcon = {
  size24: <Settings />,
}

const ClockIcon = {
  size24: <Clock />,
}

export const data = [
  {
    title: "Только лучшие решения для наших пользователей",
    icon: MasterIcon,
    mobileIcon: SettingsIcon,
    skin: "blue",
    text:
      "На основе персональных данных и требований банков мы подбираем выгодные финансовые продукты",
  },
  {
    title: "Экономим ваше время",
    icon: StopwatchIcon,
    mobileIcon: ClockIcon,
    skin: "yellow",
    text:
      "Для получения кредита или оформления вклада нашим пользователям не нужно посещать офис банка — весь процесс проходит онлайн",
  },
]
