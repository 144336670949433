import React from "react"
import { Link } from "gatsby"
import Logo from "../../assests/images/logo.svg"

export const HeaderLogo = () => {

  return (
    <Link to={"/"}>
      <img
        src={Logo}
        alt="логотип Банки.ру"
      />
    </Link>
  )
}
