import React from "react"
import * as Scroll from "react-scroll"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Text from "@bankiru/ui-kit/core/Text"
import { LinkText } from "@bankiru/ui-kit/components/Link/LinkText";

const ScrollLink = Scroll.Link

export const HeaderNav = () => (
  <FlexboxGrid direction="row" gap="default">
    <ScrollLink spy={true} to="documents" smooth={true}>
      <Text weight="medium">
        <LinkText>
          Официальные сведения
        </LinkText>
      </Text>
    </ScrollLink>
    <ScrollLink spy={true} to="contacts" smooth={true}>
      <Text weight="medium">
        <LinkText>
          Контакты
        </LinkText>
      </Text>
    </ScrollLink>
  </FlexboxGrid>
)
