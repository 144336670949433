import styled from "styled-components"
import colors from "@bankiru/ui-kit/constants/colors.json"

export const StyledMenu = styled.nav`
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  background: ${colors.majorWhite};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-out 0.25s, opacity 0.3s ease;
  z-index: 10;
`
