import React, { useContext } from "react"
import PageContext from "../../../context/context"
import TextHeader from "@bankiru/ui-kit/core/TextHeader"
import Text from "@bankiru/ui-kit/core/Text"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"

export const PageTitle = () => {
  const { isTablet, isMobile } = useContext(PageContext)

  return (
    <FlexboxGrid isFullWidth direction="vert" gap={isMobile ? "xs" : "small"}>
      <TextHeader
        isCentered={isMobile}
        tagName="h1"
        size="h0"
        deviceType={isMobile ? "mobile" : isTablet ? "tablet" : "desktop"}
      >
        <Text isNowrap>Финансовая платформа</Text>
        <Text isNowrap>Банки.ру Маркетплейс</Text>
      </TextHeader>
      <Text
        align={isMobile ? "center" : "left"}
        size={isMobile ? "fs16" : "fs18"}
        color="secondary"
      >
        Мы помогаем людям принимать правильные финансовые решения
      </Text>
    </FlexboxGrid>
  )
}
