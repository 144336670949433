import React from "react"
import { createGlobalStyle } from "styled-components"
import colors from "@bankiru/ui-kit/constants/colors.json"

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: ${colors.blueGray40};
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, ul {
  padding: 0;
  margin: 0;
  font-size: inherit;
}

ul {
  list-style: none;
}

button {
  background: none;
	border: none;
	padding: 0;
	outline: none;
  &:hover {
		cursor: pointer;
  }
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
`

export const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
)
