import React, { useContext } from "react"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Area from "@bankiru/ui-kit/core/Area"
import Text from "@bankiru/ui-kit/core/Text/Text"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import { LinkButton } from "@bankiru/ui-kit/components/Link/LinkButton";
import { ADDRESS, MAIL, PHONE_NUMBER } from "../../constants"
import PageContext from "../../context/context"
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"

export const Footer = () => {
  const { isDesktop, isMobile } = useContext(PageContext)

  return (
    <footer id={"contacts"}>
      <Area padding={3} bgColor="dark-blue">
        <LayoutWrapper isNoPadding={!isDesktop}>
          <FlexboxGrid
            isEqualWidth
            direction={isDesktop ? "row" : "vert"}
            justifyContent="space-between"
            alignItems="center"
            gap={isMobile ? "small" : "medium"}
          >
            <Text
              align={isDesktop ? "left" : "center"}
              color="tertiary"
              size="fs12"
            >
              Акционерное общество «Банки.ру Маркетплейс» ОГРН 1217700577819
            </Text>
            <Text align="center" size="fs12" color="tertiary">
              {ADDRESS}
            </Text>
            <FlexboxGrid justifyContent={isDesktop ? "end" : "center"}>
              <LinkButton size="fs12" href={`mailto:${MAIL}`} skin="blue">
                {MAIL}
              </LinkButton>
              <LinkButton size="fs12" href={`tel:${PHONE_NUMBER}`} skin="blue">
                {formatPhoneNumber(PHONE_NUMBER)}
              </LinkButton>
            </FlexboxGrid>
          </FlexboxGrid>
        </LayoutWrapper>
      </Area>
    </footer>
  )
}
