import React, { useContext } from "react"
import Button from "@bankiru/ui-kit/core/Button"
import PageContext from "../../../context/context"
import { LINK_TO_DEPOSITS } from "../../../constants"

export const CreditButton = () => {
  const { isMobile } = useContext(PageContext)

  return (
    <Button href={LINK_TO_DEPOSITS} size={isMobile ? "h40" : "h48"}>
      Подобрать вклад
    </Button>
  )
}
