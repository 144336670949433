import React, { useEffect, useState } from "react"
import * as Scroll from "react-scroll"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Avatar from "@bankiru/ui-kit/core/Avatar"
import UserProfile24 from "@bankiru/icons/react/UserProfile24"
import MenuIcon from "@bankiru/icons/react/Menu16"
import CloseIcon from "@bankiru/icons/react/Close16"
import { LinkButton } from "@bankiru/ui-kit/components/Link/LinkButton";
import IconWrapper from "@bankiru/ui-kit/core/IconWrapper"
import { HeaderLogo } from "../HeaderLogo"
import { LinkWrapper } from "./index.styled"
import { MobileMenu } from "./MobileMenu"
import Text from "@bankiru/ui-kit/core/Text";
import { LINK_TO_APPLICATIONS } from "../../constants";

const ScrollLink = Scroll.Link

export const HeaderMobile = () => {
  const [open, setOpen] = useState(false)
  const [overflow, setOverflow] = useState("")

  useEffect(() => {
    const overflowBody = document.body.style.overflow
    if (overflowBody && overflowBody !== "hidden") setOverflow(overflowBody)

    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = overflow
    }
  }, [open, overflow])

  return (
    <>
      <FlexboxGrid
        onClick={() => (open ? setOpen(!open) : null)}
        justifyContent="space-between"
        alignItems="center"
      >
        <IconWrapper isBlockElement onClick={() => setOpen(!open)}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconWrapper>
        <HeaderLogo />
        <LinkButton href={LINK_TO_APPLICATIONS}>
          <Avatar src={<UserProfile24 />} alt="avatar" size="24" />
        </LinkButton>
      </FlexboxGrid>
      <MobileMenu open={open}>
        <FlexboxGrid direction={"vert"} gap={"zero"}>
          <LinkWrapper>
            <LinkButton size="fs16">
              <ScrollLink
                spy={true}
                to="documents"
                smooth={true}
                onClick={() => (open ? setOpen(!open) : null)}
              >
                <Text weight="medium">
                  Официальные сведения
                </Text>
              </ScrollLink>
            </LinkButton>
          </LinkWrapper>
          <LinkWrapper>
            <LinkButton size="fs16">
              <ScrollLink
                spy={true}
                to="contacts"
                smooth={true}
                onClick={() => (open ? setOpen(!open) : null)}
              >
                <Text weight="medium">
                  Контакты
                </Text>
              </ScrollLink>
            </LinkButton>
          </LinkWrapper>
        </FlexboxGrid>
      </MobileMenu>
    </>
  )
}
