import breakpoints from "@bankiru/ui-kit/constants/breakpoints.json"

export function getDeviceType() {
  const deviceType = {
    isDesktop: false,
    isTablet: false,
    isMobile: false,
  }

  if (window.matchMedia(`(min-width: ${breakpoints.lg})`).matches) {
    deviceType.isDesktop = true
  } else if (window.matchMedia(`(min-width: ${breakpoints.sm})`).matches) {
    deviceType.isTablet = true
  } else {
    deviceType.isMobile = true
  }

  return deviceType
}
