import React from "react"
import { Helmet } from "react-helmet"
import { Home } from "../components"

const IndexPage = () => (
  <>
    <Helmet>
      <html lang="ru" />
      <title>Финансовая платформа</title>
      <link
        href="//fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap&subset=cyrillic"
        rel="stylesheet"
      />
      <meta
        name="description"
        content="Финансовая платформа. Банки.ру Маркетплейс. Мы помогаем людям принимать правильные финансовые решения"
      />
      <meta name="keywords" content="подобрать кредит" />
      <script src="/counters/yandex-metrika.js" type="text/javascript"></script>
    </Helmet>
    <noscript>
      <div>
        <img
          src="https://mc.yandex.ru/watch/92461277"
          style={{ position: "absolute", left: "-9999px" }}
          alt=""
        />
      </div>
    </noscript>
    <Home />
  </>
)
export default IndexPage
